<template>
    <div class="form_modal" >
        <div class="modal2">
            <a class="close_modal"
            @click="$emit('close')"
            >
                <img style="background: var(--close-modal);" alt="">
            </a>
            <h2>
                Спасибо, ваша заявка {{TiketID}} принята в работу
                <br>Статус Заявки {{StatusID}}
            </h2>
            <h3>
               
            </h3>

        <button @click="$emit('close')" >Закрыть окно</button> 
               
         
           
        
        </div>

    </div>
  
</template>
<script>
export default {
    data(){
        return{
            TiketID:'',
            StatusID:'',
        }

    },
    mounted() {

            if (localStorage.tiketID, localStorage.statusID) {
      this.TiketID = localStorage.tiketID;
      this.StatusID = localStorage.statusID;
    }
    if (localStorage.tiketID, localStorage.statusID) {
      this.TiketID = localStorage.tiketID;
      this.StatusID = localStorage.statusID;
    }
    // if (localStorage.statusID) {
    //   this.StatusID = localStorage.statusID;
    // }
    


  },
  




}
</script>


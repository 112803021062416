<template>
    <section>
    <nav >
        <div class="topnav desctop">
            <div class="topnav_logo" ><router-link to="/"><img id="myImagesLogo" src="/img/icons/log.svg" alt=""></router-link></div>
            <div class="topnav_pages">
                <router-link to="/Intercom" active-class="active">Умный домофон</router-link>
                <router-link to="/Video" active-class="active">Видеонаблюдение</router-link>
                <router-link to="/Gates" active-class="active">Ворота и шлагбаумы</router-link>
                <router-link to="/ACMS" active-class="active">СКУД</router-link>
                <div class="dropdown">
                    <button class="dropbtn">О нас
                        <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="dropdown-content">
                        <router-link to="/About" active-class="active">О нас</router-link>
                        <router-link to="/Docs" active-class="active">Документы</router-link>
                    </div>
                </div>

            </div>
            <div class="topnav_button">
                <button  @click="dark=!dark" class="btnhed" ><img  id="myImagesSheme" src="/img/icons/sheme.svg" alt=""></button>
                <button class="NavFo" id="NavFo"
              @click="sShowFoModal=true,modal=!modal, modal=true"
              >
                <p>Подать заявку</p>
              </button>
              <ShowFoModal
              v-if="sShowFoModal"
              @close="sShowFoModal = false, nextM=false, modal=false"
              @nextMclose = "nextM = false"
              @nextMopen = "nextM = true"

              />
             
              
              <nextModal
              v-if="nextM"
              @nextMclose = "nextM = false"
              @nextMopen = "nextM = true"
              @close="sShowFoModal = false, nextM=false,modal=false" 

              />
            </div>

        </div>

    </nav>
    <nav class="mobile">
      <Slide class="bm-slide" >
        <router-link to="/" active-class="active"><img src="/img/iconsBurger/homeicon.svg" alt=""> Главная </router-link>
        <button @click="sShowFoModal=true,modal=!modal, modal=true" v-bind:class="{ButtonActive: isBut}"  id="NavFo" class="issues"><img src="/img/iconsBurger/issuesicon.svg" alt="">Подать заявку</button>
        <router-link to="/Video" active-class="active"><img src="/img/iconsBurger/videoicon.svg" alt="">Видеонаблюдение</router-link>
        <router-link to="/Intercom" active-class="active"><img src="/img/iconsBurger/intericon.svg" alt="">Умный домофон</router-link>
        <router-link to="/Gates" active-class="active"><img src="/img/iconsBurger/gateicon.svg" alt="">Ворота и шлагбаумы</router-link>
        <router-link to="/ACMS" active-class="active"><img src="/img/iconsBurger/acmsicon.svg" alt="">СКУД</router-link>
        <router-link to="/About" active-class="active"><img src="/img/iconsBurger/abouticon.svg" alt="">О нас</router-link>
        <router-link to="/Docs" active-class="active"><img src="/img/iconsBurger/docicon.svg" alt="">Документы</router-link>
        </Slide>
        <div class="topnav_logo" ><router-link to="/"><img id="myImagesLogoM" src="/img/icons/logm.svg" alt=""></router-link></div>
        <button  @click="dark=!dark" class="btnhed"  ><img img id="myImagesShemeM" src="/img/icons/sheme.svg" alt=""></button>
        <ShowFoModal
              v-if="sShowFoModal"
              @close="sShowFoModal = false, nextM=false, modal=false"
              @nextMclose = "nextM = false"
              @nextMopen = "nextM = true"

              />
             
              
              <nextModal
              v-if="nextM"
              @nextMclose = "nextM = false"
              @nextMopen = "nextM = true"
              @close="sShowFoModal = false, nextM=false,modal=false" 

              />
      </nav>
    </section>

</template>

<script >
import { Slide } from 'vue3-burger-menu';
import ShowFoModal from '@/components/modal/ShowFoModal.vue';
import nextModal from '@/components/modal/nextModal.vue';
import axios from 'axios';
export default {
  name:"Nav",
    data(){
        return{
            dark: false,
            sShowFoModal: false,
            nextM: false,
            modal:false,
            isBut:false,
        }
    },
    components: {
        Slide, // Register your component
        ShowFoModal,
        nextModal,
    },

    mounted() {

      this.root = document.documentElement;

        axios.get('https://api.domofon-37.ru/zamand/api/v1/monitoring/health_check?token=8rYlBkO5a9l4R0Lcd39n6qfLvG8QZWMy3cOulA8fUVpn5kraG0Og8Q')
                .then(response => this.isBut=false)
                .catch(error => this.isBut=true);

    },

     watch: {       
      dark: {
      handler: function() {
        this.$nextTick(() => {
          if (this.dark) {
            localStorage.color= 'Dark';
            this.root.style.setProperty("--tele-byt-color", "#23E3C6")
            this.root.style.setProperty("--main-background-color", "#150621");
            this.root.style.setProperty("--text-color-light", "white");
            this.root.style.setProperty("--main-second-color-bg","#1E2235");
            this.root.style.setProperty("--footer-color", "#1E2235");
            this.root.style.setProperty("--but-light",' url("./asset/light.svg")');
            this.root.style.setProperty("--drop-down-color","#1E2235") ;
            this.root.style.setProperty("--close-modal",'url("./asset/close_dark.svg")');
            this.root.style.setProperty("--close-modal",'url("./asset/close_dark.svg")');
            document.getElementById('myImagesSheme').src = '/img/icons/shemedark.svg';
            document.getElementById('myImagesLogo').src = '/img/icons/darcklog.svg';
            document.getElementById('myImagesShemeM').src = '/img/icons/shemedark.svg';
            document.getElementById('myImagesLogoM').src = '/img/icons/icondark.svg';
            this.root.style.setProperty("--close-modal",'url("/img/closeD.svg")');
            this.root.style.setProperty("--load-img",'url("/img/icons/icondark.svg")');
            this.root.style.setProperty("--bg-video", 'url("/img/bg-videod.webp")');
            this.root.style.setProperty("--color-gr","#1E2235");
            this.root.style.setProperty("--block-inform-bg","#23E3C6") ; 
            this.root.style.setProperty("--icon-card1",'url("../img/CardIcon/IconCardD1.svg")');
            this.root.style.setProperty("--icon-card2",'url("../img/CardIcon/IconCardD2.svg")');
            this.root.style.setProperty("--icon-card3",'url("../img/CardIcon/IconCardD3.svg")');
            this.root.style.setProperty("--icon-card4",'url("../img/CardIcon/IconCardD4.svg")');

          } else {
            localStorage.color= 'Light';
            this.root.style.setProperty("--tele-byt-color", "#000000")
            this.root.style.setProperty("--main-background-color", "white");
            this.root.style.setProperty("--text-color-light", "black");
            this.root.style.setProperty("--main-second-color-bg","#23E3C6");
            this.root.style.setProperty("--footer-color", "#0B8E7A");
            this.root.style.setProperty("--but-light",' url("./asset/darkmod.svg")');
            this.root.style.setProperty("--drop-down-color","white")
            this.root.style.setProperty("--close-modal",'url("../asset/close.svg")');
            document.getElementById('myImagesLogo').src = '/img/icons/log.svg';
            document.getElementById('myImagesSheme').src = '/img/icons/sheme.svg';
            document.getElementById('myImagesLogoM').src = '/img/icons/logm.svg';
            document.getElementById('myImagesShemeM').src = '/img/icons/sheme.svg';
            this.root.style.setProperty("--close-modal",'url("/img/closeL.svg")');
            this.root.style.setProperty("--load-img",'url("/img/icons/log.svg")');
            this.root.style.setProperty("--bg-video", 'url("/img/bg-video.webp")');
            this.root.style.setProperty("--color-gr","#F6F5F5") ;
            this.root.style.setProperty("--block-inform-bg","#0B8E7A");
            this.root.style.setProperty("--icon-card1",'url("../img/CardIcon/IconCard1.svg")');
            this.root.style.setProperty("--icon-card2",'url("../img/CardIcon/IconCard2.svg")');
            this.root.style.setProperty("--icon-card3",'url("../img/CardIcon/IconCard3.svg")');
            this.root.style.setProperty("--icon-card4",'url("../img/CardIcon/IconCard4.svg")');
          }
        })
      },
      immediate: true
    },
    modal: {
      
    handler: function() {
      
        this.$nextTick(() => {
          if (this.modal) {
            localStorage.modal= 'true';
            document.body.classList.add('modal_open');
          } else {
            localStorage.modal= 'false';
            document.body.classList.remove('modal_open');
          }
       
  })
  
},
immediate: true
    }
  },
  created(){
    
    if (localStorage.color) {
    if (localStorage.color == 'Light') {
      this.dark=false


    } else {
      this.dark=true

    }
    }else {
      this.dark=  window.matchMedia('(prefers-color-scheme: dark)').matches;
      
    }
  },
}
</script>

<style>

.ButtonActive{
  display: none;
}
.line-style {
	position: absolute;
	height: 10%;
	left: 0;
	right: 0;
	width: 100%;
	border-radius: 20px;
}
.bm-burger-button {
	position: absolute;
	width: 40px;
	height: 30px;
	left: 1em;
	top: auto;
	cursor: pointer;
}
.bm-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: var(--main-background-color);
    overflow-x: hidden;
    padding-top: 60px;
    -webkit-transition: .5s;
    transition: .5s;
  }
.bm-item-list > *{
margin-left: 10%;
align-items: center;
gap: .7em;
}  
  .bm-item-list a{
    font: var(--font-mob-medium-bold);
    color: var(--text-color-light);
}
nav .active{
  color: var(--main-second-color);
}
.bm-slide {
	width: 40px;
	height: 30px;
}
</style>
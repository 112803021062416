<template>
    <div class="form_modal" >
        <div id="lables" class="modal">
            <a class="close_modal"
            @click="$emit('close',)">

            </a>
            <h1> Сервис временно не доступен </h1>
            <h1> Прниносим свои извинения  </h1>
            <h1> Попробуйте позже  </h1>
            <!-- <img src="/img/smile.png" alt=""> -->
            <h2>
                Заполните, пожалуйста, эту форму, спасибо
            </h2>
            <h3>
                * Обязательно к заполнению
            </h3>
            <form action="" class="form" id="form" @submit.prevent="createPost"   >
                <div class="fio">
                <div class="surname">
                    <fieldset>
                    <label><strong>Фамилия </strong><span>*</span></label>
                    <input type="text" placeholder="Иванов " id="surname" v-model="state.lastname">
                    
                    <span
                class="red-error"
                v-if="v$.lastname.$error &&! v$.lastname.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
                </div>
            
            <div class="name">
                <fieldset >
                    <label><strong>Имя </strong><span>*</span></label>
                <input type="text" placeholder="Иван " id="name" v-model="state.firstname">
                <span
                class="red-error"
                v-if="v$.firstname.$error &&! v$.firstname.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
            </div>
            
            
            <div class="patrony">
                <fieldset>
                <label ><strong>Отчество</strong></label>
                <input type="text" placeholder="Иванович " id="patronymic" v-model="state.middlename">


            </fieldset>
            </div>
        </div>
            
            
        
        <div>
            <fieldset>
                <label><strong>Номер телефона </strong><span>*</span></label>
            <input 
            type="tel" 
            autocomplete="tel"
            placeholder="8(555) 555-5555"
            id="phone"
            v-model="state.phone"

            >
            <span
                class="red-error"
                v-if="v$.phone.$error &&! v$.phone.$required "
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>
        <div>
            <fieldset>
            <label><strong>Электронная почта</strong><span>*</span></label>
            <input type="email" placeholder="пример@домен.com" id="mail" v-model="state.email">
            
            <span
                class="red-error"
                v-if="v$.email.$error &&! v$.email.$required"
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>
        <div>
            <fieldset>
            <label><strong>Адрес проживания</strong><span>*</span></label>
            <input type="text" placeholder="Иваново улица Жиделева, 21" id="address" v-model="state.address">
            
            <span
                class="red-error"
                v-if="v$.address.$error &&! v$.address.$required"
                >Поле должно быть заполнено</span>
            </fieldset>
        </div>

        <div class="message">
            <label><strong>Сообщение</strong><span>*</span></label>
            
            <textarea type="text"  id="massage" v-model="state.message">
           </textarea>
           
                    <span
                class="red-error"
                v-if="v$.message.$error &&! v$.message.$required "
                >Поле должно быть заполнено</span>
        </div>
        <div class="ok">
            
            <input type="checkbox" class="Check" v-model="state.check">
            
            
            <p>Подтверждаю своё согласие на обработку <br>
                <a href="https://nextcloud.domofon-37.ru/index.php/s/PQpRA36tHY4CfYG" target="_blank">персональных данных</a></p>
            
        </div>
        <span
                class="red-error"
                v-if="v$.check.$error &&! v$.check.$required  "
                >Поле должно быть заполнено</span> 
        <button >Подать заявку{{listItems}}</button> 
               
         
            </form>



        </div>

    </div>

</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, required } from '@vuelidate/validators';
import { computed, reactive } from "vue";
import axios from 'axios'


export default{
    data(){
        return{
            response:'',
            ID: '',
            tiketID:'',
            StatusCode:'',
        }
    },

    setup(){

        const state = reactive({
            v$: useVuelidate(),
            firstname:'',
            lastname:'',
            middlename:'',
            check: '',
            phone:'',
            email:'',
            message:'',
            address:'',

        })
        const rules = computed(()=>{
            return{
                firstname: { 
                    text:"" ,
                    required },
                lastname:{
                    text:"" ,
                    required },
                check:{ required },
                phone:{required,
                number: '',
                minLength: minLength(11),
                    },
                message:{
                    text:"" ,
                    required},
                email:{required, email},
                address:{
                    text:"",
                    required},
            }
        })

        const v$ = useVuelidate(rules, state)
        return{
            state,
            v$,
        }
    },


methods:{
     createPost() {
let customerE = this.state.email
let FName = this.state.firstname
let LName = this.state.lastname
let MName = this.state.middlename
let IPhone = this.state.phone
let Tmessage = this.state.message
let Aaddress = this.state.address
    this.v$.$validate()
    if(!this.v$.$error){
    $.ajax({
        url: 'https://api.domofon-37.ru/zamand/api/v1/tickets',         /* Куда отправить запрос */
        method: 'post',             /* Метод запроса (post или get) */
        dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
        headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
        data:{
            title: "Заявка с сайта " + Aaddress,
            group_id: "2",
            address:Aaddress,
            customer: customerE,
            article: {
                subject: "My subject",
                body: Tmessage,
                type: "note",
                internal: false,

                    }
                    
            },
        success: function(data, status, jqXHR){
                this.ID = true
                this.tiketID = data.number
                localStorage.tiketID= this.tiketID;
                this.StatusCode = jqXHR.status
                localStorage.statusID= this.StatusCode;
                console.log(jqXHR.status)
            },
statusCode:{
    422: function(){
$.ajax({
url: 'https://api.domofon-37.ru/zamand/api/v1/users',         /* Куда отправить запрос */
method: 'post',             /* Метод запроса (post или get) */
dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
    data:{
        firstname: FName,
        lastname: LName,
        middlename: MName,
        email: customerE,
        phone: IPhone,
        },/* Данные передаваемые в массиве */
statusCode:{ 
    201: function(){
        $.ajax({
            url: 'https://api.domofon-37.ru/zamand/api/v1/tickets',         /* Куда отправить запрос */
            method: 'post',             /* Метод запроса (post или get) */
            dataType: 'json',          /* Тип данных в ответе (xml, json, script, html). */
            headers:{'Authorization': `Token token=6SpSTMtQcCT9v5OGKulgWEIeS-pnw6vukXCuw6r2A3kchQ3VeB8z614QT-6CJ126`},
            data:{
                title: "Заявка с сайта " + Aaddress,
                group_id: "2",
                customer: customerE,
                article: {
                    subject: "My subject",
                    body: Tmessage,
                    type: "note",
                    internal: false
                        },
                    },
                success: function(data, status,jqXHR){
                    this.ID = true
                    this.tiketID = data.number
                    this.StatusCode = jqXHR.status
                    localStorage.statusID= this.StatusCode;
                    console.log(jqXHR.status)
                            },/* Данные передаваемые в массиве */
                    },
                    );
                    },
                    },/* функция которая будет выполнена после успешного запроса.  */
        });
                    },

            },

            });
            setTimeout(() => {
             this.$emit('nextMopen', this.ID = true)  
            }, 1000);
            

      
    }       
    

      else{

        }
        

       
},
},
mounted(){

    // setTimeout(() => {
        axios.get('https://api.domofon-37.ru/zamand/api/v1/monitoring/health_check?token=8rYlBkO5a9l4R0Lcd39n6qfLvG8QZWMy3cOulA8fUVpn5kraG0Og8Q')
                .then(response => $("modal"))
                .catch(error =>   $("#lables").removeClass("modal").addClass("hui"))
// }, "4000", console.log("1"));


}

}
</script>
<style>
.hui {
	min-height: 20vw;
	width: 710px;
	background: var(--drop-down-color);
	position: relative;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 5px;
	max-height: 765px;
}
.hui h1{
    text-align: center;
    color: var(--text-color-light);
    font: var(--fonts-size-large-bold);
}    
.hui form{
    display:none
}
.hui h2{
    display:none
}
.hui h3{
    display:none
}
.hui p{
    display:none
}
@media screen and (orientation: portrait)
{
    .hui {
        min-height: 50vw;
        width: 710px;
        background: var(--drop-down-color);
        position: relative;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        max-height: 765px;
    }
    .hui h1{
        text-align: center;
        color: var(--text-color-light);
        font: var(--font-mob-h1-bold);
    }    
    .hui form{
        display:none
    }
    .hui h2{
        display:none
    }
    .hui h3{
        display:none
    }
    .hui p{
        display:none
    }
}
    
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home/Home.vue'
import Nav from '@/components/Nav.vue'

const routes = [
  {
    path: "/",
    redirect: Home,
  },
  {
    path:"/",
    name: 'Home',
    component: Home
  },
  {
    path: '/Video',
    name: 'Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Surveillance/Surveillance.vue')
    }
  },
  {
    path: '/Intercom',
    name: 'Intercom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Intercom/Intercom.vue')
    }
  },
  {
    path: '/ACMS',
    name: 'ACMS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/ACMS/ACMS.vue')
    }
  },
  {
    path: '/Gates',
    name: 'Gates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Gates/Gates.vue')
    }
  },
  {
    path: '/About',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/About/About.vue')
    }
  },
  {
    path: '/Docs',
    name: 'Docs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Docs/docs.vue')
    }
  },
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

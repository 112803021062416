<template>
<div class="footer">
    <div class="footer_block">
        <h1>
            Наши контакты
        </h1>
        <div class="footer_block-cont">
            <div class="footer_block-cont-num">
                <a href="tel:+74932343809" class="fut_bl5">+7 (4932) 34‒38‒09</a>
                <a href="tel:+74932366685" class="fut_bl3">+7 (4932) 36‒66‒85</a>
            </div>

            <p>Принимаем звонки с 9:00 до 18:00 c понедельника по четверг. В пятницу с 9:00 до 17:00</p>
            <a href="https://yandex.ru/maps/org/domovoy_servis/55362562175/?from=mapframe&ll=40.968926%2C57.010230&z=17 ">Г. Иваново, ул. Жиделева, 21, 204 офис, 2 этаж</a>
            <p>Офис работает по графику с 9:00 до 14:00 c понедельника по пятницу</p>
        </div>
    </div>
    <div class="footer_block">
        <h1>
            Услуги
        </h1>
        <div class="footer_block-cont">
            <router-link to="Video"><p>Видеонаблюдение</p></router-link>
            <router-link to="Intercom"><p>Умный домофон</p></router-link>
            <router-link to="Gates"><p>Ворота и шлакбаумы</p></router-link>
            <router-link to="ACMS"><p>СКУД</p></router-link>
        </div>
    </div>
    <div class="footer_block alt">
        <h1>
            Дополнительная информация
        </h1>
        <div class="footer_block-alt" >
            <div class="footer_block-cont ">
                <router-link to="/docs">Документы</router-link>
                <p class="img-qr">Скачать приложение</p>
                <p class="no-qr">Вы можете скачать наше приложение по кнопке ниже</p>
                <button target="_blank"  ><a href="https://domofon-37.ru/mobilelink/">Скачать приложение</a></button>
                <a href="https://domofon-37.ru/mobilelink/"><img  class="img-qr" src="/img/qr-code.svg" alt=""></a>
            </div>
            <div class="footer_block-cont img-res">
                <p>Рейтинг</p>
                <iframe src="https://yandex.ru/sprav/widget/rating-badge/55362562175?type=rating" width="150" height="50" frameborder="0"></iframe>
                <p>Социальные сети</p>
                <a href="https://t.me/domofon_37_bot" target="_blank"><img  src="../assets/iconTg.svg" alt=""></a>
                
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
name:"Footer",
components: {
      },
data(){
    return{

        }
    },
methods:{

}
}
</script>
<template>  
<div class="map">
    <h1>Наши контакты и адреса</h1>
        <div class="map_contact">
            <div class="map_contact-contacts">
                <div class="contacts-contact">
                    <img src="@/assets/map/contact1.svg" alt="">
                    <a href="tel:+74932343809" class="fut_bl5">+7 (4932) 34‒38‒09</a>
                </div>
                <div class="contacts-contact">
                    <img src="@/assets/map/contact1.svg" alt="">
                    <a href="tel:+74932366685">+7 (4932) 36‒66‒85</a>
                </div>
                <div class="contacts-contact">
                    <img src="@/assets/map/contact2.svg" alt="">
                    <a href="https://yandex.ru/maps/5/ivanovo/?ll=40.967751%2C57.010139&mode=poi&poi%5Bpoint%5D=40.967812%2C57.010260&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D55362562175&source=serp_navig&z=21" target="_blank">г. Иваново, ул. Жиделева, 21, 204 офис, 2 этаж</a>
                </div>
                <div class="contacts-contact">
                    <img src="@/assets/map/contact3.svg" alt="">
                    <a href="mailto:dom@domofon-37.ru" target="_blank">dom@domofon-37.ru</a>
                </div>
            </div>
            <div class="map_contact-yanmap" id="map_block" >
                <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=55362562175" class="yanmap_map" frameborder="0"></iframe>
            </div>
        </div>
    </div>
    <div class="mapmob" id="map">
            <h1>Наши контакты и адреса</h1>

        <div class="contact_block">
            <div class="contacts">
                <div class="contact">
                    <img src="@/assets/map/contact1.svg" alt="">
                    <a href="tel:+74932343809" class="fut_bl5">+7 (4932) 34‒38‒09</a>
                </div>
                <div class="contact">
                    <img src="@/assets/map/contact1.svg" alt="">
                    <a href="tel:+74932366685">+7 (4932) 36‒66‒85</a>
                </div>
                <div class="contact contact-add">
                    <img src="@/assets/map/contact2.svg" alt="">
                    <a href="https://yandex.ru/maps/5/ivanovo/?ll=40.967751%2C57.010139&mode=poi&poi%5Bpoint%5D=40.967812%2C57.010260&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D55362562175&source=serp_navig&z=21" target="_blank">г. Иваново, ул. Жиделева, 21, 204 офис, 2 этаж</a>
                </div>
                <div class="contact">
                    <img src="@/assets/map/contact3.svg" alt="">
                    <a href="mailto:dom@domofon-37.ru" target="_blank">dom@domofon-37.ru</a>
                </div>
            </div>
            <div class="map_block" id="map_block" >
                <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=55362562175" class="yanmap_map" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>
<script>
// export default{
//     created(){
//     let center = [57.010265, 40.967800];

// function init() {

// 	let map = new ymaps.Map('map_block', {
// 		center: center,
// 		zoom: 17
// 	});
//     let placemark = new ymaps.Placemark(center, {}, {
// 		iconLayout: 'default#image',
// 		iconImageSize: [40, 40],
// 		iconImageOffset: [-19, -44],
//         });
//     map.controls.remove('searchControl');
// 	map.geoObjects.add(placemark);

// }

// ymaps.ready(init);
// },
// }
</script>

import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import jquery from 'jquery';
import axios from 'axios';


window.$ = window.jQuery = require('jquery');

createApp(App)
.use(store)
.use(router)
.use(axios)
.use(jquery)
.mount('#app')

<template>
 <!-- <div id="preloader">
    <div style="display: contents;" >
        <img src="/img/icons/log.svg" alt="" style="position: absolute;">
            <div class='loader'></div>
      </div>
    </div> -->
  <section class="wrapmap atimap">
    <ShowFoModal v-if="sShowFoModal"
    @close="sShowFoModal = false, nextM=false, modal=false" @nextMclose = "nextM = false" @nextMopen = "nextM = true"
    />             
    <nextModal v-if="nextM"
    @nextMclose = "nextM = false" @nextMopen = "nextM = true" @close="sShowFoModal = false, nextM=false,modal=false"
    />
    <swiper     
    :centeredSlides="true"

    :loop="true"
    :pagination="{
      clickable: true,
    }"
    :autoplay="true"
    :navigation="true" 
    :modules="modules" 
    class="mySwiper">
    
      <swiper-slide>
        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>Домовой сервис —обеспечиваем безопасность имущества и жизни наших клиентов с наибольшим комфортом для вас</h1>
            <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
            <button @click="sShowFoModal=true,modal=!modal, modal=true">Подать заявку</button>
            
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_first.webp" alt="">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>

        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>Видеонаблюдение — помогает решить проблемы на придомовой территории</h1>
            <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
            <button @click="sShowFoModal=true,modal=!modal, modal=true">Подать заявку</button>
            
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_second.webp" alt="">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>Ворота и шлагбаумы — надёжные защитники вашей придомовой территории</h1>
            <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
            <button @click="sShowFoModal=true,modal=!modal, modal=true">Подать заявку</button>
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_four.webp" alt="">
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>Умный домофон - неоценимый помощник в вашей повседневной жизни</h1>
            <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
            <button @click="sShowFoModal=true,modal=!modal, modal=true">Подать заявку</button>
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_three.webp" alt="">
          </div>
        </div>
      </swiper-slide>
      <!-- <swiper-slide>
        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>СКУД — система контроля и управления доступом. Одна из главных связующий нашего сервиса</h1>
            <p>Подключаем, обслуживаем и обеспечиваем надёжную поддержку наших систем.</p>
            <button @click="sShowFoModal=true,modal=!modal, modal=true">Подать заявку</button>
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_five.webp" alt="">
          </div>
        </div>
      </swiper-slide> -->
      <swiper-slide>
        <div class="swiper_container ">
          <div class="swiper_container_content">
            <h1>Все необходимое в одном приложении — комфорт на расстоянии одного касания</h1>
            <p>Наше приложение для управления домофонами и видеонаблюдением предлагает множество функциональных преимуществ, которые делают вашу жизнь более удобной и безопасной.</p>
            <div class="swiper_container-but ">
              <div class="swiper_butt-fir">
                <a href="https://clck.ru/3GiJN2" target="_blank"><button><img src="@/assets/google.webp" alt="">Google Play</button></a>
                <a href="https://clck.ru/3GiJJq" target="_blank"><button><img src="@/assets/apple.webp" alt="">App store</button></a>
                <a href="https://clck.ru/3GiJWu" target="_blank"><button><img src="@/assets/rustor.webp" alt="">RuStore</button></a>
              </div>

              <div class="swiper_butt-sec">
                  <a href="https://clck.ru/3GiJFC" target="_blank"><button><img src="@/assets/app.webp" alt="">App Gallery</button></a>
                  <a href="https://clck.ru/3GiJv7" target="_blank"><button><img src="@/assets/web.webp" alt="">Web-Версия</button></a>
              </div>
          </div>
            
          </div>
          <div class="swiper_container_img">
            <img src="/img/swiperImage/Swiper_six.webp" alt="">
          </div>
        </div>
      </swiper-slide>
  </swiper>
</section>
<section class="ourservices">
  <div class="ourservices_wrapp">
    <h1>С чем вам помогут наши услуги</h1>
    <div class="ourservices_wrapp-card">
      <div class="card">
        <div class="iconcard1" src="img/CardIcon/IconCard1.svg" alt=""></div>
        <h2>Безопастновсть придомовой территории</h2>
        <p>Даём вам возможность держать под контролем территорию вокруг дома. Круглосуточный доступ к камерам на придомовой территории.
          Возможность посмотреть в реальном времени кому вы открываете дверь, калитку или шлагбаум.</p>
      </div>
      <div class="card">
        <div class="iconcard2" src="img/CardIcon/IconCard2.svg" alt=""></div>
        <h2>Архив записей с камер и вызовов</h2>
        <p>Если произошла непредвиденная проблема на придомовой или внутридомовой территории — 
          то доступ к архиву поможет вам разобраться с подобного рода проблемами.</p>
      </div>
      <div class="card">
        <div class="iconcard3" src="img/CardIcon/IconCard3.svg" alt=""></div>
        <h2>Удобство в использовании</h2>
        <p>За счёт современных технологий, которые не перегружены ненужной для пользователя информацией, 
          позволяет вам приоткрыть для себя мир будущего. Осторожно, умный домофон облегчает вашу жизнь и вызывает привыкание.</p>
      </div>
      <div class="card">
        <div class="iconcard4" src="img/CardIcon/IconCard4.svg" alt=""></div>
        <h2>Никого лишнего, только свои</h2>
        <p>Подделать ключ от подъезда? Взломать домофон, шлагбаум или калитку? Сразу отвечаем вам, что это решительно невозможно. 
          Мы приложили все навыки наших специалистов, чтобы система безопасности работала на вас, а не против вас.</p>
      </div>
    </div>
  </div>
</section>
<section id="services">
        <h1>Наши услуги</h1>
        <div class="services_slider">
        <div class="items">
            <div class="item active">
                <div class="slide">
                    <img src="/img/UslugSlider/SliderSecond1.png" alt="">
                    <router-link  to="/Intercom"><a href="">Умный домофон</a></router-link>
                </div>
            </div>
            <div class=" item next">
                <div class="slide">
                    <img src="/img/UslugSlider/SliderSecond3.png" alt="">
                    <router-link  to="/Gates"><a href="">Ворота и шлагбаумы</a></router-link>
                </div>
            </div>
            <!-- <div class="item">
                <div class="slide">
                    <img src="/img/UslugSlider/SliderSecond4.png" alt="">
                    <router-link  to="/ACMS"><a href="">СКУД</a></router-link>
                </div>
                
            </div> -->

            <div class="item prev">
                <div class="slide">
                    <img src="/img/UslugSlider/SliderSecond2.png" alt="">
                    <router-link  to="/Video"><a href="">Видеонаблюдение</a></router-link>
                </div>
            </div>

            <div class="button-container">
                <div class="button"><img src="/img/UslugSlider/bl3prev.svg" alt=""></div>
                <div class="button"><img src="/img/UslugSlider/bl3next.svg" alt=""></div>
            </div>
        </div>
    </div>
</section>
<section class="wrapmap">
<Map></Map>
</section>
<section class="wrapmap">
  <Footer></Footer>
</section>

</template>

<script>
import ShowFoModal from '@/components/modal/ShowFoModal.vue';
import nextModal from '@/components/modal/nextModal.vue';
import Map from '@/components/Map.vue';
import Footer from '@/components/Footer.vue';
  // Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';

  

  // import required modules
  import {Autoplay,  Navigation, Pagination,} from 'swiper/modules';

export default {
  name:"Home",
  components: {
    Swiper,
    SwiperSlide,
    Map,
    Footer,
    ShowFoModal,
    nextModal,
      },
  data(){
    return{
      sShowFoModal: false,
      nextM: false,
      modal:false,
        }
    },
  setup() {
    return {
      modules: [Pagination,Autoplay, Navigation],
        };
      },
  mounted(){

    const slider = document.querySelector(".items");
    const slides = document.querySelectorAll(".item");
    const button = document.querySelectorAll(".button");

    let current = 0;
    let prev = 3;
    let next = 1;

    for (let i = 0; i < button.length; i++) {
      button[i].addEventListener("click", () => i == 0 ? gotoPrev() : gotoNext());
              }

    const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1);
    const gotoNext = () => current < 2 ? gotoNum(current + 1) : gotoNum(0);//здесь коллво
    const gotoNum = number => {
    current = number;
    prev = current - 1;
    next = current + 1;
    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove("active");
      slides[i].classList.remove("prev");
      slides[i].classList.remove("next");
                                          }
    if (next == 3) {//здесь коллво
      next = 0;
                    }
    if (prev == -1) {
      prev = 2;//здесь коллво
                    }
    slides[current].classList.add("active");
    slides[prev].classList.add("prev");
    slides[next].classList.add("next");
};

// $(document).ready(function() { 
//         document.body.classList.add('modal_open');
//           let preloader = document.getElementById('preloader');
          
//           setTimeout (function(){
//             preloader.classList.add('hide-preloader');
//             setInterval(function() {
//                   preloader.classList.add('preloader-hidden');
//                   document.body.classList.remove('modal_open');
//             },3000);},2000)
//         });
},

}
</script>

<style >

.swiper-button-prev, .swiper-button-next{
  color: #23E3C6;
  font-size: 45px;
}
.swiper-slide{
  background-color: initial;
}
@media screen and (orientation:portrait){
  .swiper-button-prev, .swiper-button-next{
display: none;
}
}
</style>